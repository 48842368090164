/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2943ea56-1342-4617-a237-e98f6f5f3471",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lqbSPifxW",
    "aws_user_pools_web_client_id": "6fj70nh7vact7g77rmigd64tfd",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://uymbh1qny7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformOpenPayApi",
            "endpoint": "https://p5see3ei10.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformLocations",
            "endpoint": "https://7ne4aett11.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformEmailService",
            "endpoint": "https://2wqp317mve.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bs7edordpjhbho4qpm3j3curfu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "trademarkplatformf86941fadafb4f1094e702518094e8234040-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
